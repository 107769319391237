import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {GET_PARTY_DETAIL} from "../../query/partyQuery";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {loadingAction} from "../../redux/loadingReducer";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT, UPDATE_PARTY_MEMBER} from "../../query/mutationQuery";
import {find, isEmpty} from "lodash";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {Comment} from "../components/Comment";
import {UserProfile} from "../components/UserProfile";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {Dialog} from "@mui/material";
import {PositiveButton} from "../my/PartyReqPage";
import qs from "query-string";
import {getLoginLink} from "../../toolbox/kakao";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px 12px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  gap: 18px;
  //height: 100vh;

  .meeting-details {
    background: white;
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .meeting-info {
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-info h2 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  .meeting-info p {
    font-size: 1em;
  }
  
  .description-box {
    margin-bottom: 10px;
  }
  
  .description {
    text-align: left;
    padding: 2px 0;
    font-size: 0.8em;
    color: #444444;
    line-height: 140%;
  }

  .attendees {
    text-align: center;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .guide {
    margin-top: 40px;
    display: flex;
    color: blue;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }

  //.attendee {
  //  background: #f3f3f3;
  //  padding: 10px;
  //  border-radius: 10px;
  //  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  //  text-align: center;
  //  width: 120px;
  //  position: relative;
  //  display: flex;
  //  flex-direction: column;
  //  flex-grow: 1;
  //}
  //
  //.attendee-list a {
  //  display: flex;
  //}
  //
  //.attendee img {
  //  border-radius: 50%;
  //  width: 100px;
  //  height: 100px;
  //  object-fit: cover;
  //  margin-bottom: 10px;
  //}
  //
  //.attendee p {
  //  margin: 0;
  //  font-size: 1em;
  //  color: #333;
  //  white-space: pre-wrap;
  //  word-break: break-all;
  //  flex-grow: 1;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}

  .rating-buttons {
    margin: 0 -6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 0.5rem;
  }

  .rating-buttons button {
    background: #3498db;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 5px;
    cursor: pointer;
    transition: background 0.3s;
  }

  .rating-buttons button:hover {
    background: #2980b9;
  }

  .rating-buttons button.selected {
    background: #2ecc71;
  }

  .host {
    border: 2px solid #3498db;
  }

  #comments-section {
    text-align: left;

    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }
  
  #comments-list {
    //max-height: 200px;
    //overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #comment-form {
    display: flex;
    flex-direction: column;
  }

  #user-name, #user-profile-pic, #comment-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  #comment-submit {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  #comment-submit:hover {
    background: #2980b9;
  }


  .review-save {
    margin-top: 32px;
  }
  .review-save button {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .review-save button:hover {
    background: #2980b9;
  }
`

const reviewScore = [
    {score: 2, text: '정말 맘에 들었어요. (+2)', emoji: '😍'},
    {score: 1, text: '다시 만나면 좋을 것 같아요. (+1)', emoji: '😃'},
    {score: 0, text: '다음에 우연히 만나도 좋아요. (0)', emoji: '🙂'},
    {score: -1, text: '저랑 잘 맞지 않았어요. (-1)', emoji: '😠'},
    {score: -2, text: '매너에 문제가 있었어요. (-2)', emoji: '😡'},
    {score: 'NOSHOW', text: '이 분은 불참하여 평가할 수 없습니다', emoji: '👻'},
]

export const PartyAfterPage = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const partyId = params?.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;


    const [partyDetail, setPartyDetail] = useState(null);
    const [commentList, setCommentList] = useState(null);
    const commentRef = useRef();

    const [open, setOpen] = useState(false);
    const [opened, setOpened] = useState(false);
    const [ratingUserId, setRatingUserId] = useState(null);

    const {form: commentForm, onChange} = useForm({
        body: '',
    });

    const [updatePartyMember] = useMutation(UPDATE_PARTY_MEMBER);
    const {data: queryData, refetch} = useQuery(GET_PARTY_DETAIL, {
        fetchPolicy: "cache-first",
        skip: partyId === undefined,
        variables: {id: partyId},
    });

    useEffect(() => {
        if (partyDetail && location.hash === '#comment') {
            const timer = setTimeout(() => {
                if (commentRef.current) {
                    window.scrollTo({
                        top: commentRef.current.offsetTop,
                        behavior: 'smooth',
                    })
                    commentRef.current.focus();
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [location.hash, partyDetail])

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (queryData?.party?.data) {
            const payload = flatEntity(queryData.party.data);
            setPartyDetail(payload);
            setCommentList(payload.comments);

            const myMember = find(payload.members, m => m.user.id === tokenInfo?.id);
            if (myMember && myMember.review) {

            } else {
                setOpen(true);
                setOpened(true);
            }

            if (parsed?.review && !opened) {
                setOpen(true);
                setOpened(true);
            }
            // dispatch(loadingAction.loading(false));
        } else {
            // dispatch(loadingAction.loading(true));
        }
    }, [queryData]);

    const onClickComment = () => {
        let body = commentForm.body;
        if (isEmpty(tokenInfo)) {
            alert('로그인이 필요합니다.');
            return;
        }
        if (비속어체크(body)) {
            alert('부적절한 단어가 포함되어있습니다.');
            return;
        }

        dispatch(loadingAction.loading(true));

        axios.post(SERVER_ADDRESS + '/api/comments/toParty', {
            body: body,
            target: partyId,
        }).then((res) => {
            onChange({id: 'body', value: ''});
            refetch();
            dispatch(loadingAction.loading(false));
        });
    }

    const getMy = () => {
        return find(partyDetail.members, m => m.user.id === tokenInfo.id);
    }

    const getScore = (userId) => {
        const myMember = getMy();
        if (myMember?.review?.[userId] !== undefined && myMember?.review?.[userId] !== null) {
            return find(reviewScore, r => r.score === myMember.review[userId]);
        }
        return null;
    }

    if (!partyDetail) {
        return <div>

        </div>
    }

    return <PageWrap>
        <div className="meeting-details">
            <div className="meeting-info">
                <h2>모임 정보</h2>
                <p><strong>모임 시간:</strong> {moment.tz(partyDetail.meetedAt, 'Asia/Seoul').format('YYYY년 M월 D일 HH:mm')}</p>
                <p><strong>모임 장소:</strong> {partyDetail.restaurantName} <a href={partyDetail.placeLink} rel="noopener noreferrer" target="_blank">[오시는 길]</a></p>
            </div>
            <div className="attendees">
                <h3>참석자</h3>
                <div className="attendee-list">
                    {partyDetail.members.map(member =>
                        <UserProfile u={member.user} isHost={member.isHost} score={getScore(member.user.id)?.emoji} onScore={() => {
                            setRatingUserId(member.user.id);
                            setOpen(true);
                        }}>
                        </UserProfile>
                    )}
                </div>
            </div>
            <div className="guide">
                <a href="https://copper-abrosaurus-467.notion.site/e54eb17c1906431793996892aa73089d?pvs=4" rel="noopener noreferrer" target="_blank">
                    <PositiveButton>디너모임 가이드</PositiveButton>
                </a>
                <Link to="/question">
                    <PositiveButton>랜덤대화주제 뽑기</PositiveButton>
                </Link>
            </div>
        </div>
        <div id="comments-section">
            <h3>댓글 {commentList ? commentList.length: 0}개</h3>
            <div id="comments-list">
                {commentList && commentList.map((c, index) => (
                    <Comment comment={c} canDelete={false} onDelete={() => refetch()}/>
                ))}
            </div>
            <div id="comment-form">
                <textarea id="comment-input" ref={commentRef} value={commentForm.body} onChange={(e) => onChange({id: 'body', value: e.target.value})} placeholder="다른 참석자들과 대화를 나눠보세요."></textarea>
                <button id="comment-submit" onClick={onClickComment}>댓글 작성</button>
            </div>
        </div>
        <PartyRate open={open} onClose={() => setOpen(false)} partyDetail={partyDetail} refetch={refetch} userId={ratingUserId} />
    </PageWrap>
}

const PartyRate = ({partyDetail, refetch, open, onClose, userId}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const {form: reviewForm, onChange: onChangeReview} = useForm({
    });
    const [canClose, setCanClose] = useState(false);

    const [updatePartyMember] = useMutation(UPDATE_PARTY_MEMBER);
    const [updateUser] = useMutation(UPDATE_USER);

    const getMy = () => {
        return find(partyDetail.members, m => m.user.id === tokenInfo.id);
    }

    useEffect(() => {
        if (partyDetail) {
            const myMember = find(partyDetail.members, m => m.user.id === tokenInfo.id);
            if (myMember && myMember.review) {
                setCanClose(true);
                for (let id in myMember.review) {
                    onChangeReview({id, value: myMember.review[id]});
                }
                if (userId) {
                    onChangeReview({id: userId, value: null});
                }
            } else {
                setCanClose(false);
                for (let member of partyDetail.members.filter(m => m.user.id !== tokenInfo.id)) {
                    onChangeReview({id: member.user.id, value: 0});
                }
            }
        }
    }, [partyDetail, userId]);

    const onClickRating = (id, rating) => {
        if (reviewForm[id] === rating) {
            onChangeReview({id, value: null});
        } else {
            onChangeReview({id, value: rating});
        }
    }


    const onClickReview = () => {
        if (reviewForm) {
            for (let key in reviewForm) {
                if (reviewForm[key] === null) {
                    alert('모든 평가 항목을 지정해주세요.');
                    return;
                }
            }
        }
        const myId = getMy().id;
        dispatch(loadingAction.loading(true));
        updatePartyMember({
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: myId,
                data: {
                    review: reviewForm,
                },
            },
            onCompleted: data => {
                axios.post(SERVER_ADDRESS + '/api/party-members/scoreAnalysis', {
                    userId: tokenInfo.id,
                }).then(() => {});
                dispatch(loadingAction.loading(false));
                alert('평가가 저장되었습니다.\n알고리즘에 의해 마음에 드는 분을 만날 가능성이 높아졌어요.');
                refetch();


                const payload = {
                    refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                    variables: {
                        id: tokenInfo.id,
                        data: {},
                    }
                };
                updateUser(payload).then(() => {});
                onClose();
            }
        });
        // dispatch(loadingAction.loading(false));
    }

    return <Dialog open={open} onClose={() => {
        if (canClose) {
            onClose();
        }
    }}>
        <_PartyRate>
            <h3>참석자 평가</h3>
            <div className="description-box">
                <p className="description">1. 상대방은 내가 평가한 내용을 알 수 없습니다.</p>
                <p className="description">2. 0점 이상의 평가를 한 사람에게는 비공개 프로필이 보여지고, 마이너스 점수부터는 회피 리스트에 등록됩니다.</p>
                <p className="description">3. 내가 평가한 데이터는 알고리즘에 반영되어 나의 다음 디너모임 멤버 구성에 영향을 주므로 솔직하게 평가해주세요.</p>
            </div>

            <div className="attendee-list">
                {partyDetail.members.filter(m => {
                    if (m.user.id === tokenInfo.id) {
                        return false;
                    }
                    if (userId) {
                        if (userId === m.user.id) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                }).map(member =>
                    <UserProfile u={member.user} isHost={member.isHost}>
                        <RatingBtns>
                            {reviewScore.map((rating) => {
                                if (reviewForm?.[member.user.id] !== null && reviewForm?.[member.user.id] !== rating.score) {
                                    return null;
                                }
                                return (<div
                                    key={`rating ${member.user.id} ${rating.score}`}
                                    // className={reviewForm?.[member.user.id] === rating.score? 'selected' : `score${rating.score}`}
                                    className={`score${rating.score}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onClickRating(member.user.id, rating.score);
                                    }}>
                                    <span className="emoji">{rating.emoji}</span> {`${rating.text}`}
                                </div>)
                            })}
                        </RatingBtns>
                    </UserProfile>
                )}
            </div>
            <div className="review-save">
                <button onClick={onClickReview}>평가 저장하기</button>
            </div>
        </_PartyRate>
    </Dialog>
}

const _PartyRate = styled.div`
  padding: 20px 8px;
  h3 {
    text-align: center;
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .description-box {
    margin-bottom: 10px;
  }

  .description {
    text-align: left;
    padding: 2px 0;
    font-size: 0.8em;
    color: #444444;
    line-height: 140%;
  }


  .review-save {
    padding: 8px;
    margin-top: 20px;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }
  .review-save button {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .review-save button:hover {
    background: #2980b9;
  }
`


const RatingBtns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  align-items: center;
  margin-top: 16px;
  font-size: 0.8rem;

  div {
    width: 80%;
    max-width: 300px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 6px 8px;
    cursor: pointer;
    transition: background 0.3s;
  }

  div:hover {
    background: #2980b9;
  }

  //div.selected {
  //  background: #2ecc71;
  //}


  div.score2 {
    background: #4CAF50;
    background: #0B3714;
  }

  div.score1 {
    background: #8BC34A;
    background: #147536;
  }
  div.score0 {
    background: #00ACC1;
    background: #4CAF50;
  }
  div.score-1 {
    background: #FF9800;
    background: #8BC34A;
  }
  div.score-2 {
    background: #F44336;
    .emoji {
      text-shadow: -1px -1px 0 #fff,1px -1px 0 #fff, -1px  1px 0 #fff, 1px  1px 0 #fff;
    }
  }
  div.scoreNOSHOW {
    background: #9E9E9E;
  }
`
