import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filter, find, isEmpty, sortBy} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {commaNum, genderToString} from "../../toolbox/format";
import moment from "moment-timezone";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {parseJwt} from "../../toolbox/jwt";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS, TOSS_CLIENT_KEY} from "../../index";
import {GET_PARTY_DETAIL, GET_RESTAURANTS} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {getUpcomingMeet, getNextMeetTimes} from "../../toolbox/time";
import {UserProfile} from "../components/UserProfile";
import {Party, PartyReq} from "../components/Party";
import {InviteDialog} from "./InviteDialog";
import {getPartyTicketCoupon, getSubscription, getSubscriptionCoupon} from "../../toolbox/entity";
import qs from "query-string";
import {Dialog} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {HelpBtn} from "../components/FormComponent";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {getProduct} from "../../toolbox/product";
import {v4} from "uuid";
import {isAdmin} from "../../toolbox/logic";


// const RESTAURANTS = ["라브리크 서울", "아올 강남", "띠엘로", "중식당표량", "후추포인트 강남"]

export const PartyReqPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [updateUser] = useMutation(UPDATE_USER);
    const [restaurants, setRestaurants] = useState(null);
    const [invite, setInvite] = useState(false);
    const [enter, setEnter] = useState(false);
    const [enterMeetedAt, setEnterMeetedAt] = useState(null);
    const [partyType, setPartyType] = useState('NORMAL');
    const [guide, setGuide] = useState(false);

    const {data: restaurantsData, refetch} = useQuery(GET_RESTAURANTS, {
        fetchPolicy: "cache-first",
    });

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (parsed && parsed.invite) {
            setInvite(true);
        }
    }, []);

    useEffect(() => {
        if (restaurantsData?.restaurants?.data) {
            const payload = flatEntity(restaurantsData?.restaurants.data);
            setRestaurants(payload);
        }
    }, [restaurantsData]);

    let partyCount = 0;
    if (userInfo?.tickets) {
        partyCount = filter(userInfo.tickets, t => !t.member).length;
    }

    if (userInfo?.partyMembers.length < 3) {
        partyCount += 3 - userInfo.partyMembers.length;
    }

    if (userInfo?.rewardGauge) {
        partyCount += userInfo.rewardGauge / 100;
    }


    const getPastMeet = (partyMembers) => {
        return sortBy(filter(partyMembers, pm => !pm.party.building && moment.tz(pm.party.meetedAt, 'Asia/Seoul') < moment.tz('Asia/Seoul')).map(pm => ({partyMember: pm, party: pm.party})), p => p.party.meetedAt).reverse();
    }

    const getBuildingMeet = (partyMembers) => {
        return sortBy(filter(partyMembers, pm => pm.party.building).map(pm => ({partyMember: pm, party: pm.party})), p => p.party.meetedAt).reverse();
    }

    const getParties = (partyMembers) => {
        if (partyMembers) {
            return sortBy(partyMembers.map(pm => ({partyMember: pm, party: pm.party})), p => p.party.meetedAt).reverse();
        }
        return [];
    }

    const findBuildingMeet = (meetedAt) => {
        let parties = getBuildingMeet(userInfo?.partyMembers);
        return find(parties, p => {
            const a = moment.tz(p.party.meetedAt, 'Asia/Seoul');
            const b = moment.tz(meetedAt, 'Asia/Seoul');
            return a.toISOString() === b.toISOString();
        });
    }

    // const handleChangeAutoEnter = async () => {
    //     const payload = {
    //         refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
    //         variables: {
    //             id: tokenInfo.id,
    //             data: {
    //                 autoEnter: !userInfo.autoEnter,
    //             }
    //         }
    //     };
    //     dispatch(loadingAction.loading(true));
    //     await updateUser(payload);
    //     dispatch(loadingAction.loading(false));
    // }

    const handleExit = async (p) => {
        if (window.confirm("모임 참가를 취소하시겠습니까?")) {
            dispatch(loadingAction.loading(true));
            await axios.post(SERVER_ADDRESS + '/api/parties/exit', {
                partyId: p.id,
            });
            const payload = {
                refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                variables: {
                    id: tokenInfo.id,
                    data: {
                        // autoEnter: userInfo.autoEnter,
                    }
                }
            };
            await updateUser(payload);
            dispatch(loadingAction.loading(false));
        }
    }

    const handleEnter = async (m, partyType) => {
        if (!partyCount) {
            let coupon = getPartyTicketCoupon(userInfo);
            if (coupon && window.confirm(`사용 가능한 참석권 쿠폰이 있습니다. 사용하시겠습니까?\n(${coupon.name})`)) {
                dispatch(loadingAction.loading(true));
                const response = await axios.post(SERVER_ADDRESS + '/api/coupons/useCoupon', {
                    couponId: coupon.id,
                });
                dispatch(loadingAction.loading(false));
                if (response.data.result === 'error') {
                    alert(response.data.msg);
                    return;
                } else if (response.data.result === 'success') {
                    const payload = {
                        refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                        variables: {
                            id: tokenInfo.id,
                            data: {},
                        }
                    };
                    dispatch(loadingAction.loading(true));
                    await updateUser(payload);
                    dispatch(loadingAction.loading(false));
                    alert('쿠폰이 정상적으로 사용되었습니다.');
                }
            } else {
                alert('참석 가능한 횟수가 부족합니다. 횟수를 구매 후 이용해주세요.');
                navigate('/purchase');
                return;
            }
            // let subscription = getSubscription(userInfo);
            // if (!subscription) {
            //     // 미구독 상태
            //     let coupon = getSubscriptionCoupon(userInfo);
            //     if (coupon && window.confirm(`사용 가능한 쿠폰이 있습니다. 사용하시겠습니까?\n(${coupon.name})`)) {
            //         dispatch(loadingAction.loading(true));
            //         const response = await axios.post(SERVER_ADDRESS + '/api/coupons/useCoupon', {
            //             couponId: coupon.id,
            //         });
            //         if (response.data.result === 'error') {
            //             alert(response.data.msg);
            //         } else if (response.data.result === 'success') {
            //             const payload = {
            //                 refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            //                 variables: {
            //                     id: tokenInfo.id,
            //                     data: {},
            //                 }
            //             };
            //             await updateUser(payload);
            //             alert('쿠폰이 정상적으로 사용되었습니다.');
            //             setEnterMeetedAt(m);
            //             setEnter(true);
            //         }
            //         dispatch(loadingAction.loading(false));
            //         return null;
            //     }
            //     alert('구독 후 이용 가능합니다. 구독 후 이용해주세요.');
            //     navigate('/subscription');
            //     return;
            // }
        }
        setEnterMeetedAt(m);
        setPartyType(partyType);
        setEnter(true);
    }

    if (isEmpty(tokenInfo)) {
        return <div></div>
    }

    return (
        <Container>
            <MeetingsSection>
                <MeetingTitle>디너모임 일정</MeetingTitle>
                <MeetingList>
                    {getNextMeetTimes().map((m, index, arr) => {
                        let p = findBuildingMeet(m);
                        let isHost = false;
                        if (p && p.party.partyType === 'CUSTOM' && p.party.memberDetail.creatorId === tokenInfo.id) {
                            isHost = true;
                        }
                        if (p && p.party.partyType === 'PUBLIC' && p.party.memberDetail.creatorId === tokenInfo.id) {
                            isHost = true;
                        }
                        const isLast = index === arr.length - 1;
                        const canCustom = isAdmin(tokenInfo.id);
                        // if (isHost) {
                        //     return <PartyReq meetedAt={m} isHost={isHost} partyType={p.party.partyType} partyId={p.party.id} requested={true} handleExit={() => handleExit(p.party)} isLast={isLast} />
                        // }
                        if (p) {
                            return <>
                                <h3>{moment.tz(m, 'Asia/Seoul').format('M월 D일(dd) A hh:mm ')}</h3>
                                <PartyReq meetedAt={m} isHost={isHost} partyType={p.party.partyType} partyId={p.party.id} requested={true} handleExit={() => handleExit(p.party)} />
                                {!isLast && <Line style={{marginTop: '12px', marginBottom: '12px'}} />}
                            </>
                        }
                        return <>
                            <h3>{moment.tz(m, 'Asia/Seoul').format('M월 D일(dd) A hh:mm ')}</h3>
                            <PartyReq meetedAt={m} canCustom={canCustom} partyType={"NORMAL"} requested={false} handleEnter={() => handleEnter(m, 'NORMAL')} handleCustom={() => handleEnter(m, 'CUSTOM')} />
                            <PartyReq meetedAt={m} canCustom={false} partyType={"ONLINE"} requested={false} handleEnter={() => handleEnter(m, 'ONLINE')} />
                            {!isLast && <Line style={{marginTop: '12px', marginBottom: '12px'}} />}
                        </>
                    })}
                </MeetingList>

                <Line style={{marginTop: '52px', marginBottom: '32px'}} />
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                    <MembershipInfo>
                        <span>보유한 참석권 (신청가능횟수) : {partyCount}회</span>
                    </MembershipInfo>
                    <div>
                        <MembershipInfo>
                            <span>보유한 초대장 : {(userInfo?.inviteMax - userInfo?.inviteSends?.length) ?? 0}장</span>
                            <div className="purchase-button" onClick={() => setInvite(true)}>초대하기</div>
                        </MembershipInfo>
                        <p style={{marginTop: '2px', color: '#808080', fontSize: '14px'}}>심심해 하는 친구에게 새로운 친구를 만날 기회를 선물해주세요.</p>
                    </div>
                    <p style={{color: '#808080', fontSize: '14px'}}>구독 시 매월 참석권 1장, 초대권 1장이 생성됩니다.</p>
                </div>
                <Line style={{marginTop: '52px', marginBottom: '32px'}} />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <PositiveButton onClick={() => setGuide(true)}>디너모임은 어떻게 진행되나요?</PositiveButton>
                </div>
            </MeetingsSection>
            <InviteDialog open={invite} onClose={() => setInvite(false)} />
            <GuideDialog open={guide} onClose={() => setGuide(false)} />
            <EnterDialog open={enter} onClose={() => setEnter(false)} meetedAt={enterMeetedAt} partyType={partyType} />
        </Container>
    );
};

const EnterDialog = ({meetedAt, partyType, open, onClose}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const [isHost, setIsHost] = useState(true);
    const [revealScope, setRevealScope] = useState('FRIEND');

    const m = moment.tz(meetedAt, 'Asia/Seoul');
    let confirmText = `${m.format('M월 D일')} 모임을 신청하시겠습니까?\n\n`
        + `※ 취소가능기한: ${m.clone().subtract(3, 'days').format('M월 D일 HH:mm')} (취소가능기한 이후에는 취소/환불이 불가합니다.)\n`
        + `※ 모임이 확정되면 알림톡으로 참석멤버와 식사장소를 안내드립니다. (보통 모임 이틀 전 화요일에 확정돼요.)\n`
        + `※ 참가비와 별도로 저녁 식대는 각자 결제합니다.`

    if (partyType === 'ONLINE') {
        confirmText = `${m.format('M월 D일')} 모임을 신청하시겠습니까?\n\n`
            + '온라인 디너모임은 남녀 성비를 고려하여 2~6인이 영상통화(Google Meet)로 함께하는 저녁식사입니다. 각자 식사할 메뉴를 준비하며 모임시작 5분 전에 입장 가능한 링크를 전달해드립니다.\n'
            + `※ 취소가능기한: ${m.clone().subtract(3, 'days').format('M월 D일 HH:mm')} (취소가능기한 이후에는 취소/환불이 불가합니다.)\n`
            + `※ 모임이 확정되면 알림톡으로 참석멤버를 안내드립니다. (보통 모임 이틀 전 화요일에 확정돼요.)`
    }

    useEffect(() => {
        if (userInfo?.hostCheck === true || userInfo?.hostCheck === false) {
            setIsHost(userInfo.hostCheck);
        }
    }, [userInfo]);

    async function handlePayment(_partyType) {
        const tossPayments = await loadTossPayments(TOSS_CLIENT_KEY);
        const product = getProduct('RESERVE');
        if (!product) {
            alert('준비중인 상품입니다.');
            return null;
        }
        const m = moment.tz(meetedAt, 'Asia/Seoul');
        const _isHost = partyType === 'CUSTOM'? true: isHost;
        const orderId = `${tokenInfo.id}_${product.id}_${m.format('YYYY-MM-DD')}_${v4()}`;
        await tossPayments.requestPayment('카드', {
            orderId: orderId,
            amount: product.price,
            customerName: userInfo.nickname,
            orderName: product.name,
            successUrl: `${window.location.origin}/payment_success?returnUrl=${location.pathname + "?" + encodeURIComponent(location.search.substr(1)) + encodeURIComponent(location.hash)}&param=${encodeURIComponent(JSON.stringify({
                meetedAt: m.toISOString(),
                isHost: _isHost,
                partyType: _partyType,
                revealScope: revealScope,
            }))}`,
            failUrl: window.location.origin + '/payment_fail',
        }).then((value) => {
            console.log(value);
        }).catch((reason => {
            alert(reason);
        }));
    }

    const onEnter = async (_partyType) => {
        if (!window.confirm("모임 참석 신청을 위해 예약금 10,000원의 결제가 필요합니다.\n" +
            "예약금은 모임 확정 전 취소하거나 모임에 참석하실 경우 전액 반환됩니다.")) {
            return;
        }

        await handlePayment(_partyType);


        // dispatch(loadingAction.loading(true));
        // await axios.post(SERVER_ADDRESS + '/api/parties/enter', {
        //     meetedAt: m.toISOString(),
        //     isHost: isHost,
        // });
        // window.fbq('track', 'Schedule');
        // const payload = {
        //     refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
        //     variables: {
        //         id: tokenInfo.id,
        //         data: {
        //         }
        //     }
        // };
        // await updateUser(payload);
        // dispatch(loadingAction.loading(false));
        //
        // if (!userInfo?.introduce) {
        //     alert("신청이 완료되었습니다.\n모임이 확정되기전에 프로필을 작성해주세요");
        //     navigate('/profile_edit?type=public');
        //     return null;
        // } else {
        //     if (window.confirm('신청이 완료되었습니다.\n신청 내역 페이지로 이동하시겠습니까?')) {
        //         navigate('/my/party');
        //     }
        // }
        // onClose();
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogContainer>
            {confirmText}
            <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                {partyType !== 'CUSTOM' && <>
                    <div style={{border: '1px solid #808080', padding: '4px', gap: '4px', display: 'flex', flexDirection: 'column'}}>
                        <div>
                            나의 참석 소식 공개범위
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '6px', lineHeight: '140%', fontSize: '14px', color: '#808080'}}>
                            <span style={{color: 'black', cursor: 'pointer'}} onClick={() => setRevealScope('FRIEND')} ><input type="radio" checked={revealScope === 'FRIEND'}/> 1번 이상 만난 우디너들만 볼 수 있어요.</span>
                            <span style={{color: 'black', cursor: 'pointer'}} onClick={() => setRevealScope('PRIVATE')}><input type="radio" checked={revealScope === 'PRIVATE'} /> 아무한테도 알리고 싶지 않아요.</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '6px', lineHeight: '140%', fontSize: '14px', color: '#808080'}}>
                            <span>※ 내 회피리스트에 올라간 사람에게는 공개되지 않습니다.</span>
                        </div>
                    </div>

                    <div style={{border: '1px solid #808080', marginBottom: '4px', padding: '4px', gap: '4px', display: 'flex', flexDirection: 'column'}}>
                        <div onClick={() => setIsHost(!isHost)} style={{cursor: 'pointer'}}>
                            <input type="checkbox" checked={isHost} /> 호스트가 가능합니다.
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '6px', lineHeight: '140%', fontSize: '14px', color: '#808080'}}>
                            <span>호스트는 부담 없이 모임의 시작/끝, 결제 등 진행을 도와주는 역할입니다.</span>
                            <span>호스트로 도와주시면 참석자들에게 좋은 선호도를 받게 됩니다.</span>
                            <span>호스트 선정 여부는 모임 확정 시 알려드립니다.</span>
                        </div>
                    </div>
                </>}
                <div style={{display: 'flex', gap: '8px'}}>
                    {partyType === 'CUSTOM' && <>
                        <PositiveButton style={{flexGrow: '1'}} onClick={async () => {
                            await onEnter('PUBLIC');
                        }}>공개 모임 만들기</PositiveButton>
                        <PositiveButton style={{flexGrow: '1'}} onClick={async () => {
                            await onEnter('CUSTOM');
                        }}>우디 모임 만들기</PositiveButton>
                    </>}
                    {partyType !== 'CUSTOM' && <>
                        <PositiveButton style={{flexGrow: '1'}} onClick={async () => {
                            await onEnter(partyType);
                        }}>신청하기</PositiveButton>
                    </>}
                </div>
            </div>
        </DialogContainer>
    </Dialog>
}

const GuideDialog = ({open, onClose}) => {
    return <Dialog open={open} onClose={onClose}>
        <DialogContainer>
            {/*<h3>디너모임은 어떻게 진행되나요?</h3>*/}
            <ReactMarkdown components={{h2: (props) => (<h2 style={{marginTop: '24px'}} {...props}/>)}}>{`
## 디너모임은 어떻게 진행되나요?

우연한디너 모임은 매칭알고리즘을 기반으로 4~6인 사이로 구성되며, 매주 목요일 오후 7시부터 9시까지 2시간 동안 강남역 인근 식당에서 진행됩니다.

신청 후 모임이 확정되면 알림톡을 통해 확정 문자가 발송됩니다. ‘나의 디너모임’에서 식사 장소와 참석 멤버들의프로필을 확인해보세요. 디너모임이 확정되면 취소 및 환불이 불가합니다.

참석자 중 1인이 오늘 모임의 호스트로 지정되며, 모임가이드(전체 참석자들에게 알림톡으로 발송)를 참고하여 모임을 진행해주세요.

## 모임 진행순서

식당 도착 (19:00)

메뉴 주문하기 (19:00 ~ 19:10)

아이스브레이킹 (19:10 ~ 19:30)

자기소개 / 자유대화 (19:30~20:00)

자리 바꾸기 (20:00)

자유대화 / 질문 타임 (20:00~21:00)

디너모임 종료  (21:00)

참석자 피드백 하기 (모임 이후)

## 레스토랑 리스트

아올 (서울 강남구 강남대로94길 28)

라브리크 서울 (서울 강남구 봉은사로18길 66)

82올리언즈 (서울 강남구 강남대로98길 28)

스트릿 (서울 강남구 역삼로1길 17)

표량 (서울 서초구 서운로 133)`}</ReactMarkdown>
        </DialogContainer>
    </Dialog>
}

const DialogContainer = styled.div`
  word-break: keep-all;
  font-family: "Elice Digital Baeum", sans-serif;
  padding: 16px;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: pre-wrap;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  
  
`

const Line = styled.div`
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`
// Styled-Components를 사용하여 스타일 정의
const Container = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
  min-height: 100vh;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${({ isDisable, isActive }) => (isDisable? '#cccccc': isActive ? '#007bff' : '#fff')};
  color: ${({ isDisable, isActive }) => (isDisable? '#999999': isActive ? '#fff' : '#000')};
  border: ${({ isDisable }) => (isDisable? '#999999': '1px solid #007bff')};
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ isDisable, isActive }) => (isDisable? '': isActive ? '#0056b3' : '#e0e0e0')};
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
  
  > :last-child {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background-color: #0056b3;
    }

    @media only screen and (min-width: 768px) {
      margin-top: 0;
      margin-left: auto;
    }
  }
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const Bio = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
  p {
    margin: 0;
    white-space: pre-wrap; /* 여러 줄의 텍스트를 지원하기 위해 추가 */
  }
`;

const ProfileInfo = styled.div`
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }
`;

const Nickname = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Gender = styled.p`
  font-size: 1rem;
`;

const PhoneNumber = styled.p`
  font-size: 1rem;
`;

const SNSLink = styled.p`
  font-size: 1rem;
`;

const MembershipInfo = styled.p`
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 12px;

  > .purchase-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const PositiveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border: none;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`
const MeetingsSection = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 440px;



  > .purchase-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #218838;
    }
  }
`;

const LogoutButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; 

  &:hover {
    background-color: #c82333;
  }
`;

const MeetingTitle = styled.h2`
  margin-top: 24px;
  font-size: 1.5rem;
  margin-bottom: 32px;
`;

const MeetingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const MeetingItem = styled.div`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #007bff;
  }

  //&:last-child {
  //  border-bottom: none;
  //}
`;
