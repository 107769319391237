import styled, {css} from "styled-components";
import {genderToString, toPhoneFormat} from "../../toolbox/format";
import {openAddress} from "../../toolbox/logic";
import moment from "moment-timezone";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {loadingAction} from "../../redux/loadingReducer";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@apollo/client";
import {DELETE_COMMENT} from "../../query/mutationQuery";
import {HelpBtn} from "./FormComponent";
import {Link, useNavigate} from "react-router-dom";
import {SmallPicture} from "./SmallPicture";
import {Popover} from "@mui/material";
import {calcAge} from "../../toolbox/time";

export const TinyProfile = ({u}) => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isClick, setIsClick] = useState(false);
    const [hoveredAt, setHoveredAt] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setHoveredAt(moment.tz('Asia/Seoul'));
    };

    const handleClick = (event) => {
        let now = moment.tz('Asia/Seoul');

        if (hoveredAt) {
            const diffInMs = now.diff(hoveredAt);
            if (diffInMs > 100) {
                navigate('/user/' + u.id);
            }
        }

        setAnchorEl(event.currentTarget);
        setIsClick(true);
        if (isClick) {
            navigate('/user/' + u.id);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setIsClick(false);
        setHoveredAt(null);
    };

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getProfile', {
            userId: u.id,
        }).then((res) => {
            if (res.data) {
                setProfile(res.data);
            }
        });
    }, []);

    const open = Boolean(anchorEl);
    return <>
        <_TinyProfile
            onClick={handleClick}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            <div className="profile">
                <SmallPicture style={{width: '48px', height: '48px'}} src={profile?.profileImage?.url ??  "/defaultProfile2.png"} />
            </div>
        </_TinyProfile>

        <Link to={"/user/" + u.id}>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableScrollLock
                disableRestoreFocus
            >
                <div style={{padding: '4px'}}>{profile?.nickname}</div>
            </Popover>
        </Link>
    </>
}


const _TinyProfile = styled.div`
  cursor: pointer;
  background: rgb(243, 243, 243);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const UserProfile = ({u, isHost, showGender, showAge, children, score, onScore}) => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getProfile', {
            userId: u.id,
        }).then((res) => {
            if (res.data) {
                setProfile(res.data);
            }
        });
    }

    const introOverflow = () => {
        let splitted = profile.introduce.split('\n');
        if (profile?.introduce.length > 30) {
            return <p className="bio">{profile.introduce.substring(0, 30)}... <span style={{fontWeight: 'bold', color: 'blue'}}>더 보기</span></p>
        } else if (splitted.length > 3) {
            splitted.length = 2;
            return <p className="bio">{splitted.join('\n')}... <span style={{fontWeight: 'bold', color: 'blue'}}>더 보기</span></p>
        }
        return <p className="bio">{profile?.introduce}</p>;
    }

    // const toggleBlacklist = async (add) => {
    //     dispatch(loadingAction.loading(true));
    //     const res = await axios.post(SERVER_ADDRESS + '/api/party-members/blacklist', {
    //         blacklistId: Number(u.id),
    //         add: add,
    //     });
    //     fetch();
    //     dispatch(loadingAction.loading(false));
    // }
    //
    // const isShowBlack = () => {
    //     return profile?.isSeen;
    // }

    return <_UserProfile>
        <div className="profile">
            <Link to={"/user/" + u.id}>
                <SmallPicture style={{marginRight: '6px'}} src={profile?.profileImage?.url ??  "/defaultProfile2.png"} />
                {/*<img src={profile?.profileImage?.url ??  "/defaultProfile.png"} alt={`프로필 사진`} className="profile-img" />*/}
            </Link>
            <div className="attendee-info">
                <div>
                    <div className="nickname" style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <Link to={"/user/" + u.id}>{profile?.nickname} {(showGender === true || showAge === true) && '/' } {showAge && `${calcAge(profile?.birth)}세`} {showGender && `${genderToString(profile?.gender)}`}</Link>
                            {isHost && <span className="host-label">호스트</span>}
                        </div>
                        {score && <div className="score" onClick={onScore}>{score}</div>}
                    </div>
                    <div className="grade">{profile?.grade ?? '예비 우디너'}</div>
                </div>
                {profile?.introduce && <Link to={"/user/" + u.id}>{introOverflow()}</Link>}
                {profile?.score && <div style={{display: 'flex', gap: '4px', alignItems: 'center', marginTop: '42x', marginLeft: '4px', color: 'gray', fontSize: '0.8em'}}>
                    <span>(나에 대한 예상 호감도: {profile?.score} / 100) </span>
                    <HelpBtn contents={"상대방이 나에게 느낄 예상 호감도를 점수로 표시한 값입니다.\n우디의 ai가 다양한 요소를 고려하여 점수를 계산합니다."} />
                </div>}

                {/*{profile?.isSeen && <div style={{display: 'flex', gap: '4px', justifyContent: 'center', alignItems: 'center', marginTop: '42x', marginLeft: '4px', fontSize: '0.9em'}}>*/}
                {/*    <span>{moment.tz(profile.isSeen, 'Asia/Seoul').format('YYYY년 M월 D일')} {profile.seenRestaurant}에서 만남</span>*/}
                {/*</div>}*/}
                {/*{!children && <>*/}
                {/*    {isShowBlack() && <>*/}
                {/*        {profile?.isBlack ? <PositiveButton style={{marginTop: '6px'}} onClick={async () => toggleBlacklist(false)}>*/}
                {/*            안만나기 해제*/}
                {/*        </PositiveButton>:<PositiveButton style={{marginTop: '0'}} onClick={async () => toggleBlacklist(true)}>*/}
                {/*            안만나기 설정*/}
                {/*        </PositiveButton>}*/}
                {/*    </>}*/}
                {/*</>}*/}
            </div>
            {/*{(isHost === undefined && profile?.isSeen) && <span className="meet-label">{moment.tz(profile.isSeen, 'Asia/Seoul').format('YYYY년 M월 D일')} {profile.seenRestaurant}에서 만남</span>}*/}
            {/*{isHost && <span className="host-label">호스트</span>}*/}
        </div>
        {children}
    </_UserProfile>
}

const _UserProfile = styled.div`
  background: rgb(243, 243, 243);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  width: 100%;
  
  .profile {
    display: flex;
    align-items: center;
  }

  .profile-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }

  .attendee-info {
    flex-grow: 1;
    display: flex;
    gap: 4px;
    flex-direction: column;
    text-align: left;
  }

  .nickname {
    display: flex;
    gap: 4px;
    font-size: 1em;
    font-weight: bold;
    margin-left: 4px;
    color: #333;
    
    .score {
      cursor: pointer;
    }
  }
  
  .grade {
    display: flex;
    gap: 4px;
    font-size: 0.9em;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 4px;
    line-height: 80%;
    color: #808080;
    margin-bottom: 4px;
  }

  .bio {
    font-size: 0.8em;
    display: block;
    display: -webkit-box;
    margin: 5px 0 0;
    padding: 5px; /* 자기소개 문구에 내부 여백 추가 */
    background: rgba(255, 255, 255, 0.8); /* 밝은 배경색 추가 */
    color: #555; /* 텍스트 색상 설정 */
    border-radius: 5px; /* 모서리를 둥글게 설정 */
    white-space: pre-wrap; /* 줄 바꿈 허용 */
    word-break: break-all; /* 단어가 길 경우 줄 바꿈 허용 */
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .host-label {
    //position: absolute;
    //top: 5px;
    //left: 5px;
    border: 1px solid #3498db;
    color: #3498db;
    padding: 1px 4px;
    border-radius: 8px;
    font-size: 0.7em;
  }
  .meet-label {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px 7px;
    border-radius: 8px;
    font-size: 0.8em;
  }
`
